import * as yup from "yup"

import getInsightContextForCategory, {
  categoriesConstants,
} from "../../util/getInsightContextForCategory"
import {
  getNewAndExistingEngagements,
  mapLoansWithAgreementData,
} from "./mapAgreements"

/**
 * @param {object} state
 *
 * @returns {object}
 */
export const mapCaseReportData = (state) => {
  const context = getInsightContextForCategory(
    state.application.category,
    true,
    state?.analysis?.setDecision?.needAnalysis,
    true
  )
  const isMaintenance =
    state.application.category === categoriesConstants.MAINTENANCE

  const decisionTexts = isMaintenance
    ? state?.deliveries?.vedlikehold?.changes?.map((change) => {
        return {
          userAdded: change?.decisionTexts?.userAdded || [],
          generated: change?.decisionTexts?.generated || [],
        }
      })
    : []

  const hasLoan = isMaintenance
    ? true
    : state?.analysis?.setDecision?.hasLoan || false
  state.mapped.commonData.hasLoan = hasLoan

  const isTaxonomyRelevant =
    state.analysis?.setDecision?.isTaxonomyRelevant ?? false
  const showTaxonomy = isTaxonomyRelevant
  const shouldDisplayPrognose = true

  const newAndExistingCollateralEngagements = getNewAndExistingEngagements(
    state.deliveries,
    state.mapped?.collateralEngagements || []
  )
  const securityRiskData =
    state.analysis?.termsAndSecurity?.input?.securityRiskData ?? []

  const agreementRegisterData =
    mapLoansWithAgreementData(
      state.mapped.agreementData || [],
      newAndExistingCollateralEngagements,
      state.mapped.engagement.loans || [],
      securityRiskData
    ) || []
  const isForeignAccount =
    state?.application?.applicationContent.isForeignAccount ?? false
  const policy = state?.decisionTables?.policyCheckApplication
    ? {
        timestamp: state?.decisionTables?.policyCheckApplication?.timestamp,
        policies: state?.decisionTables?.policyCheckApplication?.output,
      }
    : null
  const showInnovationAndDigitalization =
    state?.analysis?.setDecision?.isInnovationAndDigitalizationRelevant ?? false
  const showSustainability =
    state?.analysis?.setDecision?.isSustainabilityRelevant ?? false
  const payload = {
    ...state?.mapped,
    context: context,
    decisionTexts,
    shouldDisplayPrognose,
    showTaxonomy,
    projectParticipants: state?.mapped?.projectParticipantsUpdated || [],
    agreementData: agreementRegisterData,
    isForeignAccount: isForeignAccount,
    policy: policy,
    showInnovationAndDigitalization: showInnovationAndDigitalization,
    showSustainability: showSustainability,
  }
  validateReportData(payload, context)

  return payload
}

const validateReportData = (data, context) => {
  // const headerSchema = yup.object().shape({

  const policySchema = yup
    .object()
    .shape({
      timestamp: yup.string().required("Required"),
      policies: yup.array().of(
        yup.object().shape({
          title: yup.string().required("Required"),
          value: yup.string().required("Required"),
        })
      ),
    })
    .nullable()

  const companySchema = yup.object().shape({
    female: yup.string().required("Required"),
    male: yup.string().required("Required"),
    infoChanges: yup.string().required("Required"),
    projectName: yup.string().required("Required"),
    orgNr: yup.string().required("Required"),
    events: yup.array().of(
      yup.object().shape({
        date: yup.string().required("Required"),
        type: yup.string().required("Required"),
      })
    ),
    eosRating: yup.object().shape({
      title: yup.string().required("Required"),
      value: yup.string().required("Required"),
    }),
  })

  // const ownersSchema = yup.array().of(

  const caseOverviewSchema = yup.object().shape({
    loan: yup.number().required("Required"),
    grant: yup.number().required("Required"),
    guarantee: yup.number().required("Required"),
    category: yup.string().required("Required"),
    projectStart: yup.string().required("Required"),
    applicationSubmittedOn: yup.string().required("Required"),
    projectBuilding: yup.string().required("Required"),
    municipality: yup.object().shape({
      code: yup.string().required("Required"),
      name: yup.string().required("Required"),
    }),
    operationalRiskOptions: yup.array().of(
      yup.object().shape({
        title: yup.string().required("Required"),
        value: yup.number().required("Required"),
      })
    ),
    projectName: yup.string().required("Required"),
    companyRegistered: yup.string().required("Required"),
    creditRating: yup.string().required("Required"),
    paymentRemarks: yup.object().shape({
      count: yup.number().required("Required"),
      sum: yup.number().required("Required"),
      otherCurrencies: yup.array().of(yup.string().required("Required")),
    }),
    districtPolicyArea: yup.boolean().nullable(),
    selectedOperationalRisk: yup.object().shape({
      title: yup.string().required("Required"),
      value: yup.number().required("Required"),
    }),
    operationalRisk: yup.string().required("Required"),
    initialized: yup.boolean().required("Required"),
  })

  // Common schema for customer-specific fields
  const customerSpecificFieldsSchema = yup.object().shape({
    coreViewStatus: yup.string().required("Required"),
    minSideStatus: yup.string().required("Required"),
  })

  // Common schema for selected safety code
  const selectedSafetyCodeSchema = yup.object().shape({
    title: yup.string().nullable(),
    value: yup.string().nullable(),
  })

  // Common schema for loans
  const loanSchema = yup.object().shape({
    accountNumber: yup.string().required("Required"),
    productName: yup.string().required("Required"),
    category: yup.string().required("Required"),
    approveDate: yup.string().required("Required"),
    totalAmount: yup.number().required("Required"),
    payoutAmount: yup.number().required("Required"),
    bookBalance: yup.number().required("Required"),
    blockedAmount: yup.number().required("Required"),
    anulledAmount: yup.number().required("Required"),
    defaultedDays: yup.string().nullable(),
    oldestUnpaidDueDate: yup.string().nullable(),
    dueUnpaidInterest: yup.string().nullable(),
    accruedInterest: yup.string().nullable(),
    companyName: yup.string().nullable(),
    customerSpecificFields: customerSpecificFieldsSchema,
    establishmentDate: yup.string().nullable(),
    arrearsAmount: yup.string().nullable(),
    termAmount: yup.number().nullable(),
    nextDueDate: yup.string().nullable(),
    availableAmount: yup.number().required("Required"),
    currency: yup.string().required("Required"),
    purposeName: yup.string().required("Required"),
    riskGroupCode: yup.string().nullable(),
    selectedSafetyCode: selectedSafetyCodeSchema,
    interestProfile: yup.array().of(yup.object().shape({})),
    feeProfile: yup.array().of(yup.object().shape({})),
    gracePeriod: yup.array().of(yup.object().shape({})),
    interestFreePeriod: yup.array().of(yup.object().shape({})),
  })

  // Common schema for grants
  const grantSchema = yup.object().shape({
    accountNumber: yup.string().required("Required"),
    approveDate: yup.string().required("Required"),
    productName: yup.string().required("Required"),
    availableAmount: yup.number().required("Required"),
    blockedAmount: yup.number().required("Required"),
    anulledAmount: yup.number().required("Required"),
    category: yup.string().required("Required"),
    currency: yup.string().required("Required"),
    totalAmount: yup.number().required("Required"),
    payoutAmount: yup.number().required("Required"),
    bookBalance: yup.number().required("Required"),
    purposeName: yup.string().required("Required"),
    riskGroupCode: yup.string().nullable(),
    typeCode: yup.string().required("Required"),
    typeName: yup.string().required("Required"),
    customerSpecificFields: customerSpecificFieldsSchema,
    companyName: yup.string().nullable(),
    orgNo: yup.string().nullable(),
  })

  // Common schema for guarantees
  const guaranteeSchema = yup.object().shape({
    accountNumber: yup.string().required("Required"),
    approveDate: yup.string().required("Required"),
    productName: yup.string().required("Required"),
    availableAmount: yup.number().required("Required"),
    blockedAmount: yup.number().required("Required"),
    anulledAmount: yup.number().required("Required"),
    category: yup.string().required("Required"),
    currency: yup.string().required("Required"),
    totalAmount: yup.number().required("Required"),
    payoutAmount: yup.number().required("Required"),
    bookBalance: yup.number().required("Required"),
    purposeName: yup.string().required("Required"),
    riskGroupCode: yup.string().required("Required"),
    typeCode: yup.string().required("Required"),
    typeName: yup.string().required("Required"),
    customerSpecificFields: customerSpecificFieldsSchema,
    companyName: yup.string().required("Required"),
    orgNo: yup.string().required("Required"),
  })

  // Common schema for summary
  const summarySchema = yup.object().shape({
    sumAvailableAmountLoan: yup.number().required("Required"),
    sumGrantLast5Years: yup.number().required("Required"),
    sumTotalGuarantees: yup.number().required("Required"),
    sumTotalAmountLoan: yup.number().required("Required"),
    sumAppliedLoanAmount: yup.number().required("Required"),
    sumAppliedGrantAmount: yup.number().required("Required"),
    sumAppliedGuarantees: yup.number().nullable(),
    lowRiskLoansExceptFisheriesFleetSum: yup.number().required("Required"),
    highRiskLoansSum: yup.number().required("Required"),
    fisheriesFleetLoansSum: yup.number().required("Required"),
  })

  const engagementSchema = yup.object().shape({
    loans: yup.array().of(loanSchema),
    grants: yup.array().of(grantSchema),
    guarantees: yup.array().of(guaranteeSchema),
    summary: summarySchema,
  })

  const engagementCreditGroupSchema = yup.object().shape({
    members: yup.array().of(
      yup.object().shape({
        name: yup.string().required("Required"),
        orgNr: yup.string().required("Required"),
      })
    ),
    engagements: yup.object().shape({
      loans: yup.array().of(loanSchema),
      grants: yup.array().of(grantSchema),
      guarantees: yup.array().of(guaranteeSchema),
      summary: summarySchema,
    }),
    creditGroupIsLimited: yup.boolean().required("Required"),
  })

  // const conclusionSurveySchema = yup.object().shape({ // Insight data?

  // const accountingSchema = yup.object().shape({ // Insight data?

  // const accountingCompanyGroupSchema = yup.object().shape({ // Insight data?

  const commonDataSchema = yup.object().shape({
    externalId: yup.string().required("Required"),
    caseNumber: yup.string().required("Required"),
    orgNr: yup.string().required("Required"),
    businessName: yup.string().required("Required"),
    hasLoan: yup.boolean().required("Required"),
  })

  const amountAppliedForSchema = yup.object().shape({
    loan: yup.number().required("Required"),
    grant: yup.number().required("Required"),
    guarantee: yup.number().nullable(),
  })

  const projectParticipantSchema = yup.object().shape({
    name: yup.string().required("Required"),
    role: yup.string().required("Required"),
    email: yup.string().email("Invalid email").required("Required"),
    phone: yup.string().required("Required"),
  })

  const collateralEngagementSchema = yup.object().shape({
    internalId: yup.string().required("Required"),
    type: yup.string().required("Required"),
    domain: yup.string().required("Required"),
    created: yup.string().required("Required"),
    createdBy: yup.string().required("Required"),
    updated: yup.string().required("Required"),
    updatedBy: yup.string().required("Required"),
    schemaUrl: yup.string().required("Required"),
    data: yup.object().shape({
      basicAccountInformation: yup.object().shape({
        accountNumber: yup.string().required("Required"),
        name: yup.string().required("Required"),
        accountRoles: yup.array().of(
          yup.object().shape({
            id: yup.string().required("Required"),
            role: yup.string().required("Required"),
            share: yup.number().nullable(),
          })
        ),
        accountBalance: yup.object().shape({
          bookedBalance: yup.number().required("Required"),
          bookedBalanceInLocalCurrency: yup.number().required("Required"),
          arrearsAmount: yup.number().required("Required"),
          balanceDate: yup.string().required("Required"),
        }),
        associatedAccounts: yup.array().of(yup.string().required("Required")),
        productInformation: yup.object().shape({
          productCode: yup.string().required("Required"),
          productName: yup.string().required("Required"),
        }),
      }),
    }),
  })

  const financePlanSchema = yup.object().shape({
    appliedGrant: yup
      .number()
      .required("financePlanSchema.appliedGrant Required"),
    appliedLoan: yup.number().required("Required"),
    publicFinancing: yup.number().required("Required"),
    otherFinancing: yup.number().required("Required"),
    ownerAndInvestorFinancing: yup.number().required("Required"),
    selfFinancing: yup.number().required("Required"),
    ownFinancing: yup.number().required("Required"),
    companyOwnFinancing: yup.number().required("Required"),
    totalOriginalCosts: yup.number().required("Required"),
    ownWork: yup.number().required("Required"),
    totalApprovedCosts: yup.number().required("Required"),
    actualGrant: yup.number().required("Required"),
    actualLoan: yup.number().required("Required"),
    actualLowRiskLoan: yup.number().required("Required"),
    actualHighRiskLoan: yup.number().required("Required"),
    actualSelfFinancing: yup.number().required("Required"),
    actualOwnerAndInvestorFinancing: yup.number().required("Required"),
    actualPublicFinancing: yup.number().required("Required"),
    actualOtherFinancing: yup.number().required("Required"),
    actualOwnWork: yup.number().required("Required"),
  })

  const caseMemoSchema = yup.object().shape({
    iddText: yup.object().shape({
      text: yup.string().required("Required"),
    }),
    costOverview: yup.array().of(
      yup.object().shape({
        name: yup.string().required("Required"),
        variable: yup.string().required("Required"),
        appliedFor: yup.number().required("Required"),
        approved: yup.number().required("Required"),
      })
    ),
    mappedStateAid: yup.object().shape({
      comment: yup.string().nullable(),
      proposedGrantAmount: yup.number().required(),
      proposedLoanAmount: yup.number().required(),
      sumAppliedGrantAmount: yup.number().required(),
      sumAppliedLoanAmount: yup.number().required(),
      products: yup
        .array()
        .of(
          yup.object().shape({
            product: yup.string().required(),
            productId: yup.string().required(),
            loanPurposeCode: yup.string().required(),
            deliveryStatus: yup.string().required(),
            displayName: yup.string().required(),
            productType: yup.string().required(),
            productClass: yup.string().required(),
            summary: yup.number().required(),
            rows: yup
              .array()
              .of(
                yup.object().shape({
                  activity: yup.number().required(),
                  article: yup.string().required(),
                  projectCost: yup.number().required(),
                  supportBasis: yup.number().required(),
                  fundingIntensity: yup.string().required(),
                  subsidyEffect: yup.number().required(),
                  subproducts: yup.array().of(yup.mixed()).required(),
                })
              )
              .required(),
            calculatedFields: yup
              .object()
              .shape({
                fundingIntensity: yup.number().required(),
                subsidyEffect: yup.number().required(),
                supportBasis: yup.number().required(),
              })
              .required(),
            customFields: yup
              .object()
              .shape({
                loanType: yup.string().required(),
                baseRent: yup.number().required(),
                rentMargin: yup.number().required(),
                paymentFrequency: yup.string().required(),
                maturity: yup.number().required(),
                gracePeriod: yup.number().required(),
                interestFreePeriod: yup.number().required(),
              })
              .required(),
          })
        )
        .required(),
      costOverview: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().required(),
            variable: yup.string().required(),
            appliedFor: yup.number().required(),
            approved: yup.number().required(),
          })
        )
        .required(),
      totalApprovedCosts: yup.number().required(),
      totalOriginalCosts: yup.number().required(),
      totalParticipantCosts: yup.number().nullable(),
      endDate: yup.string().required(),
      startDate: yup.string().required(),
      activities: yup
        .array()
        .of(
          yup.object().shape({
            activityType: yup.string().required(),
            supportRegime: yup.string().required(),
            products: yup
              .array()
              .of(
                yup.object().shape({
                  product: yup.string().required(),
                  amount: yup.number().required(),
                  fundingIntensity: yup.number().required(),
                  productType: yup.string().required(),
                  subsidyEffect: yup.number().required(),
                  subproducts: yup.array().of(yup.mixed()).required(),
                  displayName: yup.string().required(),
                })
              )
              .required(),
            activityStart: yup.string().required(),
            activityEnd: yup.string().required(),
            costs: yup
              .array()
              .of(
                yup.object().shape({
                  name: yup.string().required(),
                  variable: yup.string().required(),
                  appliedFor: yup.number().required(),
                  approved: yup.number().required(),
                })
              )
              .required(),
            activityTitle: yup.string().required(),
            inputTotal: yup.number().required(),
            staticTotal: yup.number().required(),
            participantCostTotal: yup.number().nullable(),
          })
        )
        .required(),
      appliedAmountDeviation: yup.string().nullable(),
      isAgriculture: yup.boolean().required(),
      showParticipantsColumn: yup.boolean().nullable(),
    }),
    specialConditions: yup.object().shape({
      category: yup.string().required(),
      appliedLoan: yup.string().nullable(),
      deliveries: yup.array().of(
        yup.object().shape({
          deliveryType: yup.string().required(),
          userAddedDeliveries: yup
            .array()
            .of(
              yup.object().shape({
                id: yup.number().required(),
                category: yup.string().required(),
                term: yup.string().required(),
                userInstructions: yup.string().required(),
                termText: yup.string().required(),
                priority: yup.number().required(),
              })
            )
            .required(),
          mandatoryDeliveries: yup.array().of(yup.mixed()).required(),
        })
      ),
    }),
    guarantors: yup.object().shape({
      guarantorText: yup.string().required(),
      comment: yup.string().nullable(),
    }),
    statisticsData: yup.object().shape({
      projectRiskRadio: yup.string().required(),
      growthPotentialProject: yup.string().required(),
      innovationLevel: yup.string().required(),
      innovationType: yup.string().required(),
      industryArea: yup.string().required(),
      otherCharacteristics: yup
        .array()
        .of(
          yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required(),
          })
        )
        .required(),
      femaleInitiativeRadio: yup.string().required(),
      numberEmployeesFemale: yup.string().required(),
      numberEmployeesMale: yup.string().required(),
      industry: yup.object().shape({
        code: yup.string().required(),
        descriptionNorwegian: yup.string().required(),
        descriptionEnglish: yup.string().required(),
        label: yup.string().required(),
      }),
      internationalNationalOrientation: yup.string().required(),
    }),
  })

  // const caseMemoSchemaMaintenance = yup.object().shape({
  //   iddText: yup.object().shape({
  //     text: yup.string().required("Required"),
  //   }),
  //   costOverview: yup.array().of(
  //     yup.object().shape({
  //       name: yup
  //         .string()
  //         .required("caseMemoSchemaMaintenance.costOverview.name Required"),
  //       variable: yup
  //         .string()
  //         .required("caseMemoSchemaMaintenance.costOverview.variable Required"),
  //       appliedFor: yup.number().required("Required"),
  //       approved: yup.number().required("Required"),
  //     })
  //   ),
  //   specialConditions: yup.object().shape({
  //     category: yup.string().required(),
  //     appliedLoan: yup.string().required(),
  //     deliveries: yup.array().of(
  //       yup.object().shape({
  //         deliveryType: yup.string().required(),
  //         userAddedDeliveries: yup
  //           .array()
  //           .of(
  //             yup.object().shape({
  //               id: yup.number().required("userAddedDeliveries.id Required"),
  //               category: yup
  //                 .string()
  //                 .required("userAddedDeliveries.category Required"),
  //               term: yup
  //                 .string()
  //                 .required("userAddedDeliveries.term Required"),
  //               userInstructions: yup
  //                 .string()
  //                 .required("userAddedDeliveries.userInstructions Required"),
  //               termText: yup
  //                 .string()
  //                 .required("userAddedDeliveries.termText Required"),
  //               priority: yup.number().nullable(),
  //             })
  //           )
  //           .required(),
  //         mandatoryDeliveries: yup.array().of(yup.mixed()).required(),
  //       })
  //     ),
  //   }),
  //   guarantors: yup.object().shape({
  //     guarantorText: yup.string().required(),
  //     comment: yup.string().nullable(),
  //   }),
  // })

  const decisionTextsSchema = yup.array().of(
    yup.object().shape({
      userAdded: yup.array().of(yup.string().required("userAdded Required")),
      generated: yup.array().of(yup.string().required("generated Required")),
    })
  )

  const bisnodeSchema = yup.object().shape({
    currentCompany: yup.object().shape({
      regNo: yup.string().nullable(),
      dunsNo: yup.string().nullable(),
      name: yup.string().required("Required"),
    }),
    companyShareholders: yup.array().of(
      yup.object().shape({
        regNo: yup.string().nullable(),
        dunsNo: yup.string().nullable(),
        name: yup.string().required("Required"),
      })
    ),
    personShareholders: yup.array().of(
      yup.object().shape({
        regNo: yup.string().nullable(),
        dunsNo: yup.string().nullable(),
        name: yup.string().required("Required"),
      })
    ),
    subsidiaries: yup.array().of(
      yup.object().shape({
        regNo: yup.string().nullable(),
        dunsNo: yup.string().nullable(),
        name: yup.string().required("Required"),
      })
    ),
  })

  // These are the mapped data in si-api-report, where most of the fails occur
  // private CaseOverview caseOverview;
  // private Engagement engagement;
  // private Company company;
  // private List<DecisionTexts> decisionTexts;
  // private CaseMemo caseMemo;
  // private CommonData commonData;
  // private IddText idd;
  // private Conclusion conclusion;
  // private JsonNode enkData;
  // private Guarantors guarantors;
  // private FinancePlan financePlan;
  // private String context;
  // private GrowthGuarantee growthGuarantee;
  // private Policy policy;
  // private JsonNode agreementData;
  // private JsonNode internalInterest;
  // private Boolean isAdvicePanelStarted;
  // private CreditGroup creditGroup;
  // private Boolean shouldDisplayKonsern;
  // private Boolean shouldDisplayPrognose;
  // private Boolean showTaxonomy;
  // private Boolean showInnovationAndDigitalization;
  // private Boolean showSustainability;
  // private Boolean isForeignAccount;
  // private List<Participant> projectParticipants;

  // Main validation schema
  const mainValidationSchema = yup.object().shape({
    amountAppliedFor: amountAppliedForSchema,
    projectParticipants: yup.array().of(projectParticipantSchema),
    collateralEngagements: yup.array().of(collateralEngagementSchema),
    financePlan: financePlanSchema,
    bisnode: bisnodeSchema,
    engagement: engagementSchema,
    creditGroup: engagementCreditGroupSchema,
    caseOverview: caseOverviewSchema,
    company: companySchema,
    commonData: commonDataSchema,
    caseMemo: caseMemoSchema,
    decisionTexts: decisionTextsSchema,

    context: yup.string().required("context Required"),
    isForeignAccount: yup.boolean().required("isForeignAccount Required"),
    shouldDisplayPrognose: yup
      .boolean()
      .required("shouldDisplayPrognose Required"),
    showTaxonomy: yup.boolean().required("showTaxonomy Required"),
    showInnovationAndDigitalization: yup
      .boolean()
      .required("showInnovationAndDigitalization Required"),
    showSustainability: yup.boolean().required("showSustainability Required"),
    isAdvicePanelStarted: yup.boolean(),

    policy: policySchema,
  })

  // const maintenanceValidationSchema = yup.object().shape({
  //   amountAppliedFor: amountAppliedForSchema,
  //   projectParticipants: yup.array().of(projectParticipantSchema),
  //   collateralEngagements: yup.array().of(collateralEngagementSchema),
  //   financePlan: financePlanSchema,
  //   bisnode: bisnodeSchema,
  //   creditGroup: engagementCreditGroupSchema,
  //   engagement: engagementSchema,
  //   caseOverview: caseOverviewSchema,
  //   company: companySchema,
  //   commonData: commonDataSchema,
  //   caseMemo: caseMemoSchemaMaintenance,
  //   decisionTexts: decisionTextsSchema,

  //   context: yup.string().required("context Required"),
  //   isForeignAccount: yup.boolean().required("isForeignAccount Required"),
  //   shouldDisplayPrognose: yup
  //     .boolean()
  //     .required("shouldDisplayPrognose Required"),
  //   showTaxonomy: yup.boolean().required("showTaxonomy Required"),
  //   showInnovationAndDigitalization: yup
  //     .boolean()
  //     .required("showInnovationAndDigitalization Required"),
  //   showSustainability: yup.boolean().required("showSustainability Required"),
  //   isAdvicePanelStarted: yup.boolean(),
  //   policy: policySchema,
  // })
  // if (context === "maintenanceDecision") {
  //   // Validate the data
  //   maintenanceValidationSchema
  //     .validate(data, { abortEarly: false })
  //     .then((validData) => {
  //       console.log("Validation succeeded:", validData)
  //     })
  //     .catch((err) => {
  //       console.log("Validation failed", JSON.stringify(err))
  //       err.errors.forEach((error) => {
  //         console.error("Validation failed:", error)
  //       })
  //     })
  // } else {
  //   // Validate the data
  // }

  mainValidationSchema
    .validate(data, { abortEarly: false })
    .then((validData) => {
      console.log("Validation succeeded:", validData)
    })
    .catch((err) => {
      console.log("Validation failed", JSON.stringify(err))
      err.errors.forEach((error) => {
        console.error("Validation failed:", error)
      })
    })

  return mainValidationSchema
}

const allowedErrors = []
