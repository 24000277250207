import React, { useEffect, useState } from "react"
import axios from "axios"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import _ from "lodash"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import { Colors } from "@flow/style"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import RequestedDocArchive from "../components/common/RequestedDocArchive"
import { returnFirstArgWithValue } from "../util/returnValue"
import { removeEmpty } from "../util/removeEmptyFieldsInObject"
import AssessmentComments from "../components/common/AssessmentComments"
import InsightModule from "../components/insight/InsightModule"
import { pollForInitializedApp } from "../components/utils/pollInsightApp"
import { useInsightAppInitialized } from "../components/insight/useInsightAppInitialized"
import Tabs from "../components/common/Tabs"
import Notice from "../components/common/Notice"
import BlueDot from "../components/common/BlueDot"
import useMunicipalityLookup from "../components/common/useMunicipalityLookup"
import calculateMultipleSubsidyEffects, {
  getSubsidyEffectParamsForDelivery,
} from "../util/calculateSubsidyEffectNew"
import { trimOperationalRisk } from "../util/trimOperationalRisk"
import { formatDate } from "../components/utils/formatDate"
import { usePrevious } from "../components/utils/hooks"
import getInsightContextForCategory from "../util/getInsightContextForCategory"
import {
  getNewAndExistingEngagements,
  mapLoansWithAgreementData,
} from "../components/utils/mapAgreements"
import useNaceLookup from "../components/common/useNaceLookup"
import { useLanguage } from "../../translate/useLanguage"
import { mapRelevantFields } from "../components/uploadStatisticalData/productionDataFields"
import { addCurrencyToEngagement, getCurrency } from "../util/getCurrency"
import { calculateSubsidyEffectGuarantee } from "../util/calculateSubsidyEffect"

const removeAdditionalProperties = (values) => {
  // remove everything except data for the option chosen to avoid data filled in for other options to bleed over
  const {
    decision,
    decisionLevel,
    rejectionTemplate,
    rejectionText,
    comment,
    needsJuridicalInspection,
    needsTinglysing,
    needsUpdateInCore,
  } = values || {}

  let legalComment
  if (needsJuridicalInspection) {
    legalComment = values.legalComment
  }

  if (decision === "OK") {
    values = {
      decision,
      decisionLevel,
      needsJuridicalInspection,
      legalComment,
      needsTinglysing,
      needsUpdateInCore,
    }
  }
  if (decision === "BACK_TO_SUPPORT") {
    values = {
      decision,
    }
  }
  if (decision === "REJECT") {
    values = {
      decision,
      decisionLevel,
      rejectionTemplate,
      rejectionText,
      comment,
      legalComment,
      needsJuridicalInspection,
    }
  }
  return values
}

const formatPayload = (
  formData,
  financePlan,
  conclusion,
  company,
  caseOverview,
  trlAndBrl,
  context,
  isExtraordinaryFinancing,
  internalInterest,
  enkData,
  agreementData,
  engagement,
  caseMemo,
  locale,
  eeaData
) => {
  const formattedConclusion = {
    comment: conclusion?.conclusion?.content?.ops
      ?.map((e) => e?.insert)
      ?.filter((elem) => typeof elem === "string")
      ?.join(""),
    createdBy: conclusion?.conclusion?.createdBy,
    timestamp: conclusion?.conclusion?.updated,
    updatedBy: conclusion?.conclusion?.updatedBy,
  }

  const formattedData = removeAdditionalProperties(formData)
  const data = {
    ...formattedData,
    financePlan,
    conclusion: formattedConclusion,
    company,
    caseOverview,
    trlAndBrl,
    context,
    enkData,
    internalInterest,
    agreementData,
    engagement,
    caseMemo,
    locale,
    eeaData,
  }
  if (isExtraordinaryFinancing) delete data.trlAndBrl
  return data
}

const CreateCreditMemo = (props) => {
  const { task, t, schema, flow, updateCase } = props
  const { data } = task
  const {
    templates = {},
    insightComponentData,
    documentation,
    creditMemoState,
    needAnalysis,
    isExtraordinaryFinancing,
    isAgriculture,
    isBioEnergy,
    isMass,
    isDtil,
    isMaintenance,
    isDsf,
    isDni,
    isEea,
    analysisVersionTag,
  } = task?.context
  delete creditMemoState?.legalComment

  const NUMBER_OF_RETRIES_FETCH_POLICY_RESULT = 10
  const RETRY_DELAY_FETCH_POLICY_RESULT = 5000

  const caseMemoOptions = {
    context: getInsightContextForCategory(
      flow.data.application.category,
      false,
      needAnalysis
    ),
    defaultCurrency: getCurrency(flow.data.application.category),
  }

  const { locale } = useLanguage()

  const [formData, setFormData] = useState(
    returnFirstArgWithValue(data?.formData, creditMemoState) || {}
  )

  const [rerenderId, setRerenderId] = useState(0)
  const [previousTemplate, setPreviousTemplate] = useState(
    formData.rejectionTemplate
  )
  const [isProcessing, setProcessing] = useState(false)
  const [activeTab, setActiveTab] = useState("creditMemo")
  const [schemaValid, setSchemaValid] = useState([])
  const [surveysNotAnswered, setSurveysNotAnswered] = useState([])

  const getDefaultOperationalRisk = (currentState = null) => {
    if (currentState?.caseOverview?.selectedOperationalRisk) {
      return trimOperationalRisk(
        currentState?.caseOverview?.selectedOperationalRisk?.title
      )
    }
    const { data: state } = flow
    return trimOperationalRisk(
      state?.caseOverview?.selectedOperationalRisk?.title ||
        state?.mapped?.caseOverview?.selectedOperationalRisk?.title
    )
  }

  const [selectedOperationalRisk, setSelectedOperationalRisk] = useState(
    getDefaultOperationalRisk()
  )
  const [subsidyEffectData, setSubsidyEffectData] = useState([])

  const [time, setTime] = useState(Date.now())
  const [showReload, setShowReload] = useState(false)
  const [isUpdatingPolicy, setIsUpdatingPolicy] = useState(false)

  /**
   * EEA evaluation
   * @type {[Insight.EeaData, function]}
   */
  const [eeaData, setEeaData] = useState(
    data?.eeaData || flow.data?.mapped?.eeaData
  )

  /**
   * @param {Insight.EeaData} data
   * @returns {void}
   */
  const handleEeaEvaluationChanged = (data) => {
    const isEeaValid = Boolean(data?.projectScore && data?.applicantType)
    data.isEeaValid = isEeaValid
    setEeaData(data)
    setFormData((prev) => ({
      ...prev,
      eeaData: data,
    }))
  }

  const isRefetchingEngagements = flow.data?.tmp?.creditGroup?.isRefetching
  const prevIsRefetching = usePrevious(isRefetchingEngagements)

  useEffect(() => {
    if (prevIsRefetching && !isRefetchingEngagements) {
      setShowReload(true)
    }
  }, [isRefetchingEngagements])

  useEffect(() => {
    if (isRefetchingEngagements) {
      const interval = setInterval(() => {
        updateCase()
        setTime(Date.now())
      }, 5000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [isRefetchingEngagements])

  /**
   * @typedef {object} ContextData - The data from the context
   * @property {object} caseOverview - The case overview.
   * @property {object} creditGroup
   * @property {object} engagement - The engagement.
   * @property {object} company - The company.
   * @property {object} caseMemo
   * @property {object} commonData - The common data.
   * @property {object} financePlan - The finance plan.
   * @property {object} trlAndBrl - The trl and brl.
   * @property {object} enkData - The enk data.
   * @property {object} internalInterest - The internal interest.
   * @property {object} agreementData - The agreement data.
   */

  /**
   * @typedef {object} TaskData
   * @property {CaseMemoFormData|null} formData - The form data.
   * @property {object} caseOverview - The case overview.
   */

  /**
   * @typedef {object} CaseMemoFormData
   * @property {string} decision - The decision.
   * @property {object} financePlan - The finance plan.
   * @property {object} conclusion - The conclusion.
   * @property {object} company - The company.
   * @property {object} caseOverview - The case overview.
   * @property {object} trlAndBrl - The trl and brl.
   * @property {object} enkData - The enk data.
   * @property {object} [agreementData] - The agreement data.
   * @property {object} internalInterest - The internal interest.
   */

  /**
   * @typedef {object} CaseMemoData
   * @property {object[]} agreementData - The agreement data.
   * @property {object} caseOverview - The case overview.
   * @property {object} creditGroup
   * @property {object} engagement - The engagement.
   * @property {object} commonData
   * @property {Insight.CaseMemo} caseMemo - The case memo.
   * @property {object} company - The company.
   * @property {object} statisticsData - The statistics data.
   * @property {object} financePlan - The finance plan.
   * @property {object} trlAndBrl - The trl and brl.
   * @property {boolean} shouldDisplayPrognose - Whether to display the prognose.
   * @property {boolean} isAdvicePanelStarted - Whether the advice panel is started.
   * @property {boolean} hasAdvicePanelContent - Whether the advice panel has content.
   * @property {boolean} hasStatementFromOthersContent - Whether the statement from others has content.
   * @property {boolean} showTaxonomy - Whether to show the taxonomy.
   * @property {boolean} showInnovationAndDigitalization - Whether to show innovation and digitalization.
   * @property {boolean} showSustainability - Whether to show sustainability.
   *  @property {boolean} hasLoan - Whether to show loan.
   * @property {object} selectedOperationalRisk - The selected operational risk.
   * @property {boolean} isDpv
   * @property {object} agricultureCategory
   * @property {object} policy
   */

  /**
   * Maps the data from the task to the data needed for the credit memo.
   * @param {ContextData} contextData - The context data.
   * @param {TaskData} taskData - The task data.
   * @returns {Insight.CaseMemoReport} - The casememo data.
   */
  const mapCreateCreditMemoData = (contextData, taskData) => {
    const updateWithSubsidyEffect = (contextDataToUpdate) => {
      // We also want to update contextDataToUpdate?.caseMemo?.mappedStateAid?.activities, and add the subsidyEffect to each activity for each loan
      if (contextDataToUpdate?.caseMemo?.mappedStateAid?.activities) {
        const activities =
          contextDataToUpdate?.caseMemo?.mappedStateAid?.activities
        contextDataToUpdate.caseMemo.mappedStateAid.activities =
          activities?.map((activity) => {
            // Each activity contains products where we want to update the subsidyEffect
            if (
              activity.supportRegime.trim() === "exemptFromSupportBasisRules" ||
              activity.supportRegime.trim() === "article22" ||
              activity.supportRegime.trim() === "startUps"
            ) {
              return activity
            }

            const updatedProducts = activity?.products?.map((product) => {
              /** @type {InFlow.Delivery} */
              const delivery = flow?.data?.deliveries[product.product]
              if (
                product.productType === "guarantee" &&
                activity.supportRegime === "trivialSupport"
              ) {
                // The product maturity is on the delivery
                const maturity = Number(delivery?.guarantee?.maturity) ?? 0
                const subsidyEffect = calculateSubsidyEffectGuarantee(
                  Number(product.amount ?? 0),
                  maturity
                )
                return {
                  ...product,
                  subsidyEffect: Math.round(Number(subsidyEffect)),
                }
              }

              if (product.productType !== "loan-with-risk") {
                return product
              }

              delivery.deliveryId = product.product
              const subsidyEffectParams = getSubsidyEffectParamsForDelivery(
                delivery,
                contextDataToUpdate?.caseOverview,
                flow?.data?.analysis?.subsidyEffectData,
                flow?.data?.analysis?.termsAndSecurity?.input?.securityRiskData,
                selectedOperationalRisk
              )
              const calculatedSubsidyEffect = calculateMultipleSubsidyEffects([
                {
                  loanAmount: +product.amount,
                  subsidyEffectParams: {
                    ...subsidyEffectParams,
                    deliveryId: product.product,
                  },
                },
              ])

              return {
                ...product,
                subsidyEffect: calculatedSubsidyEffect?.[0]?.subsidyEffect,
              }
            })
            return {
              ...activity,
              products: updatedProducts,
            }
          })
      }

      if (contextDataToUpdate?.caseMemo?.mappedStateAid?.products) {
        /** @type {Insight.MappedStateAidProduct[]} */
        const products = contextDataToUpdate?.caseMemo?.mappedStateAid?.products
        contextDataToUpdate.caseMemo.mappedStateAid.products = products?.map(
          (product) => {
            if (product.productType === "guarantee") {
              const { maturity } = product?.guarantee ?? {}
              const maturityYears = maturity / 12
              /** @type {InFlow.Delivery} */
              const delivery = flow?.data?.deliveries[product.productId]

              // The product maturity is on the delivery
              const subsidyEffect = calculateSubsidyEffectGuarantee(
                Number(delivery.amount ?? 0),
                Number(maturityYears ?? 0)
              )
              return {
                ...product,
                calculatedFields: {
                  ...product?.calculatedFields,
                  subsidyEffect,
                },
              }
            }

            const relevantSubsidyEffectData = subsidyEffectData?.find(
              (subsidyEffect) =>
                subsidyEffect?.deliveryId === product?.productId
            )
            const { subsidyEffect } = relevantSubsidyEffectData || {}

            // May need to update product.rows as well, but this seems to be done automatically somewhere (wtf)
            return {
              ...product,
              calculatedFields: {
                ...product?.calculatedFields,
                subsidyEffect,
              },
            }
          }
        )
      }

      return contextDataToUpdate
    }
    if (taskData) {
      const contextDataCopy = { ...contextData }
      const parsedTaskData = removeEmpty(taskData)

      let statisticsData = contextDataCopy.caseMemo.statisticsData
      if (parsedTaskData?.formData) {
        contextDataCopy.caseMemo.statisticsData =
          parsedTaskData?.formData?.caseMemo?.statisticsData

        statisticsData = parsedTaskData?.formData?.caseMemo?.statisticsData
      } else {
        contextDataCopy.caseMemo.statisticsData = statisticsData
      }
      contextDataCopy.caseOverview = {
        ...contextDataCopy?.caseOverview,
        ...parsedTaskData?.caseOverview,
      }
      contextDataCopy.company = {
        ...contextDataCopy?.company,
        ...parsedTaskData?.company,
      }
      contextDataCopy.financePlan = {
        ...contextDataCopy?.financePlan,
        ...parsedTaskData?.financePlan,
      }
      contextDataCopy.trlAndBrl = {
        ...contextDataCopy?.trlAndBrl,
        ...parsedTaskData?.trlAndBrl,
      }
      contextDataCopy.internalInterest = {
        ...contextDataCopy?.internalInterest,
        ...parsedTaskData?.internalInterest,
      }
      contextDataCopy.enkData = {
        ...contextDataCopy?.enkData,
        ...parsedTaskData?.enkData,
      }
      contextDataCopy.engagement = addCurrencyToEngagement(
        _.merge({}, flow.data.mapped?.engagement ?? {}, {
          ...contextDataCopy?.engagement,
          ...parsedTaskData?.engagement,
        }),
        flow.data.application.category
      )
      contextDataCopy.creditGroup = flow.data.mapped?.creditGroup

      return updateWithSubsidyEffect(contextDataCopy)
    }
    return updateWithSubsidyEffect(contextData)
  }

  const caseMemoData = mapCreateCreditMemoData(insightComponentData, data)
  caseMemoData.isDpv =
    flow?.data?.mapped.caseOverview.districtPolicyArea ?? false
  caseMemoData.selectedOperationalRisk =
    flow?.data?.mapped?.caseOverview?.selectedOperationalRisk

  if (isEea) {
    caseMemoData.eeaData = eeaData
  }

  const engagements = flow.data.mapped.engagement.loans.map((loan) => {
    return {
      ...loan,
      riskGroupCode:
        task.data?.loans?.find(
          (taskLoan) => loan.accountNumber === taskLoan.accountNumber
        )?.riskGroupCode ?? loan.riskGroupCode,
    }
  })
  const securityRiskData =
    flow.data.analysis?.termsAndSecurity?.input?.securityRiskData ?? []

  const newAndExistingCollateralEngagements = getNewAndExistingEngagements(
    flow.data.deliveries,
    flow.data.mapped?.collateralEngagements || []
  )
  const mappedLoansWithCollateralAgreements = mapLoansWithAgreementData(
    flow.data.mapped.agreementData || [],
    newAndExistingCollateralEngagements,
    engagements,
    securityRiskData
  )

  caseMemoData.shouldDisplayPrognose = true
  caseMemoData.isAdvicePanelStarted =
    flow.data?.advicePanel?.manuallyTriggered ||
    flow.data?.advicePanel?.triggered
  caseMemoData.hasAdvicePanelContent =
    flow.data?.advicePanel?.notesContent?.advicePanelHasContent ?? false
  caseMemoData.hasStatementFromOthersContent =
    flow.data?.advicePanel?.notesContent?.statemenFromOthersHasContent ?? false
  caseMemoData.showTaxonomy =
    flow.data.analysis?.setDecision?.isTaxonomyRelevant ?? false
  caseMemoData.showInnovationAndDigitalization =
    flow.data?.analysis?.setDecision?.isInnovationAndDigitalizationRelevant ??
    false
  caseMemoData.showSustainability =
    flow.data?.analysis?.setDecision?.isSustainabilityRelevant ?? false
  caseMemoData.agreementData = mappedLoansWithCollateralAgreements ?? []
  caseMemoData.agricultureCategory = isAgriculture
    ? flow?.data?.application?.applicationContent?.selectMainProduction
    : null
  caseMemoData.caseMemo.mappedStateAid.showParticipantsColumn =
    !!flow.data?.analysis?.setDecision?.input?.summaries?.totalParticipantCosts

  if (analysisVersionTag >= 1.0)
    caseMemoData.policy = {
      timestamp: flow.data.decisionTables?.policyCheckApplication?.timestamp,
      policies: flow.data.decisionTables?.policyCheckApplication?.output,
    }
  const mapAgricultureData = () => {
    const applicationContent = flow?.data?.application?.applicationContent
    const relevantAgricultureFields = !isAgriculture
      ? []
      : mapRelevantFields(applicationContent?.selectMainProduction)

    return relevantAgricultureFields.reduce((acc, field) => {
      const { beforeKey, afterKey } = field
      if (data && data[beforeKey] && data[afterKey]) {
        return {}
      }
      acc[beforeKey] = `${applicationContent[beforeKey] || 0}`
      acc[afterKey] = `${applicationContent[afterKey] || 0}`
      return acc
    }, {})
  }

  const updateStatisticsData = (existingData, additionalData = {}) => ({
    numberEmployeesMale: "0",
    numberEmployeesFemale: "0",
    ...additionalData,
    ...existingData,
  })

  if (caseMemoData?.caseMemo?.statisticsData) {
    caseMemoData.caseMemo.statisticsData = updateStatisticsData(
      caseMemoData.caseMemo.statisticsData
    )
  }

  if (isAgriculture) {
    const agricultureProductionData = mapAgricultureData()
    caseMemoData.caseMemo.statisticsData = updateStatisticsData(
      caseMemoData.caseMemo.statisticsData,
      agricultureProductionData
    )
  }

  const [appState, setAppstate] = useState({})

  const [app, setApp] = useState(null)
  const [isLoading, isErrored] = useInsightAppInitialized(app)
  let municipality =
    formData?.caseOverview?.municipality ||
    insightComponentData.caseOverview?.municipality
  const [municipalityName, municipalityLookup] = useMunicipalityLookup(
    municipality?.code
  )
  const { naceLookup } = useNaceLookup()

  useEffect(() => {
    if (municipality && municipalityName) {
      municipality.name = municipalityName
      setFormData({ ...formData })
    }
  }, [municipalityName])

  useEffect(() => {
    if (
      formData.rejectionTemplate !== previousTemplate &&
      formData.rejectionTemplate
    ) {
      const newData = {
        ...formData,
        rejectionText: templates[formData.rejectionTemplate],
      }
      setPreviousTemplate(formData.rejectionTemplate)
      setFormData(newData)
      setRerenderId(rerenderId + 1)
    }
  }, [formData])

  const getUpdatedInsightState = () => {
    return { ...appState, ...app?.getCurrentState() }
  }

  const handleSave = async () => {
    const state = getUpdatedInsightState()
    const formDataCopy = formData
    const newFormData = {
      ...formDataCopy,
      caseMemo: {
        ...formDataCopy.caseMemo,
        statisticsData: state?.statistics,
      },
    }
    if (await pollForInitializedApp(500, 30, app, setSchemaValid)) {
      setProcessing(true)
      props.save(
        { formData: newFormData, ...state, eeaData },
        () => setProcessing(false),
        () => {
          console.error("Could not save task")
          setProcessing(false)
        }
      )
    }
  }

  const handleComplete = async (values) => {
    const currentState = getUpdatedInsightState()
    setSchemaValid([])
    let validations = currentState?.validations
    if (currentState?.surveysNotAnswered) {
      setSurveysNotAnswered(currentState?.surveysNotAnswered)
    } else {
      setSurveysNotAnswered([])
    }
    let isSchemaValid = currentState?.isSchemaValid
    if (isExtraordinaryFinancing) {
      validations = currentState?.validationsUtInntBortfall
      isSchemaValid = currentState?.isSchemaValidUtInntBortfall
    }

    if (isAgriculture || isBioEnergy || isMass) {
      validations = currentState?.validationsAgriculture
      isSchemaValid = currentState?.isSchemaValidAgriculture
    }
    if (isMaintenance) {
      const { securityTexts, changes } = flow.data?.deliveries?.vedlikehold
      const securityOrDecisionTextsExists =
        securityTexts.length > 0 || changes.length > 0

      validations = currentState?.validationsMaintenance
      validations.securityOrDecisionTextsExists = securityOrDecisionTextsExists
      isSchemaValid =
        currentState?.isSchemaValidMaintenance && securityOrDecisionTextsExists
    }
    if (isDtil || isDsf || isDni) {
      validations = currentState?.validationsDtil
      isSchemaValid = currentState?.isSchemaValidDtil
    }

    if (!currentState?.caseOverview?.selectedOperationalRisk) {
      validations.customOperationalRiskNull =
        !!currentState?.caseOverview?.selectedOperationalRisk
      isSchemaValid = false
    }

    if (!isSchemaValid && values.decision === "OK") {
      setSchemaValid(
        Object.keys(validations || {}).filter((key) => !validations[key])
      )
    }
    // if no engagements answered, then we validate 🧖🏼‍♀️
    const validationsForReject = currentState?.isEngagementAnsweredReject
    if (!validationsForReject && values.decision === "REJECT") {
      validations = currentState?.validationsRejected
      setSchemaValid(
        Object.keys(validations || {}).filter((key) => !validations[key])
      )
      setProcessing(false)
      return
    }

    if (isEea && !eeaData?.isEeaValid) {
      setProcessing(false)
      setSchemaValid((prev) => [...prev, "eeaEvaluation"])
      return
    }

    // Complete if valid schema OR decision is not OK
    if (isSchemaValid || values?.decision !== "OK") {
      setProcessing(true)
      const {
        financePlan,
        trlAndBrl,
        conclusion,
        company,
        caseOverview,
        internalInterest,
        enkData,
        agreementData,
        engagement,
        statistics,
      } = getUpdatedInsightState() || {}
      const caseMemo = mapCreateCreditMemoData(caseMemoData, appState).caseMemo
      // Should we check taskData as well? There is a problem when we trigger complete after changes
      caseMemo.statisticsData = statistics

      const data = formatPayload(
        formData,
        financePlan,
        conclusion,
        company,
        caseOverview,
        trlAndBrl,
        internalInterest,
        enkData,
        agreementData,
        caseMemoOptions.context,
        isExtraordinaryFinancing,
        engagement,
        caseMemo,
        locale,
        eeaData
      )
      props.complete(
        data,
        () => setProcessing(false),
        () => {
          console.error("Could not complete task")
          setProcessing(false)
        }
      )
    }
  }

  // This function calculates the subsidy effect based on the passed in loan.
  /**
   * Calculates the subsidyeffect for the loan.
   *
   * @param {object} deliveries - The deliveries.
   * @param {object} caseOverview - The caseOverview.
   * @param {string} selectedOperationalRisk - The selected operational risk for delivery.
   * @param {object} subsidyEffectData - The subsidy effect data.
   * @param {object} securityRiskData - The security risk data.
   *
   * @returns {array} - [{loanAmount, subsidyEffectParams}] The subsidy effect data to be passed in to api-gateway.
   * @property {number} loanAmount - The amount of the loan.
   * @property {object} subsidyEffectParams - The subsidy effect params.
   */
  const parseLoans = (
    deliveries,
    caseOverview,
    selectedOperationalRisk = null,
    subsidyEffectData = {},
    securityRiskData
  ) => {
    if (!deliveries) {
      return []
    }
    const mappedDeliveries = Object.entries(deliveries)
      .map(([key, value]) => ({
        deliveryId: key,
        ...value,
      }))
      .filter(({ productClass }) => productClass === "Loan")
    return (
      mappedDeliveries?.map((delivery) => ({
        loanAmount: delivery.amount,
        subsidyEffectParams: getSubsidyEffectParamsForDelivery(
          delivery,
          caseOverview,
          subsidyEffectData,
          securityRiskData,
          selectedOperationalRisk
        ),
      })) ?? []
    )
  }

  const handleAppChange = (app) => {
    setApp(app)
  }
  const handleTabChange = (tab) => {
    const insightState = app.getCurrentState() || {}
    setAppstate({ ...appState, ...insightState })
    setActiveTab(tab)
  }

  const subsidyEffectCalculate = (value) => {
    // This function is called twice but don't try to change this (vue moment), you'll break it
    // If selected operational risk is the same as the one we already have, we don't need to recalculate

    const trimmedOperationalRisk = trimOperationalRisk(value.title)
    setSelectedOperationalRisk(trimmedOperationalRisk)

    const loans = parseLoans(
      flow?.data?.deliveries,
      flow?.data?.caseOverview,
      trimmedOperationalRisk,
      flow?.data?.analysis?.subsidyEffectData,
      flow?.data?.analysis?.termsAndSecurity?.input?.securityRiskData
    )

    const subsidyEffectResult = calculateMultipleSubsidyEffects(loans)
    setSubsidyEffectData(subsidyEffectResult)

    return subsidyEffectResult
  }

  const getTaskId = async (flowId) => {
    const res = await axios.get(
      `/api/flow/tasks?flowId=${flowId}&status=pending&taskType=trigger-policy-check-from-create-credit-memo`
    )
    const tasks = res?.data
    const { taskId } = tasks?.[0] ?? {}
    return taskId
  }

  /**
   * Triggers the policy check for the flow.
   * @param {boolean} isDpv - Whether the policy area is a district policy area.
   * @param {{title: string, value: string}} selectedOperationalRisk - The selected operational risk.
   * @param {{title: string, value: string}} eosRating - The EØS rating.
   * @returns {Promise<object[]>} - The policy check results.
   */
  const triggerPolicyCheck = async (
    isDpv,
    selectedOperationalRisk,
    eosRating
  ) => {
    const triggeredAt = new Date().toISOString()
    const flowId = flow?.flowId
    const taskId = await getTaskId(flowId)
    if (!taskId) {
      return []
    }
    setIsUpdatingPolicy(true)
    await axios.post(`/api/flow/tasks/${taskId}/trigger`, {
      districtPolicyArea: isDpv ?? null,
      selectedOperationalRisk,
      eosRating: eosRating,
    })
    // Function to fetch the task result with retries
    const fetchTaskResult = async (retryCount, delay) => {
      const res = await axios.get(
        `/api/flow/tasks?flowId=${flowId}&completedAt=>${triggeredAt}&status=completed&taskType=run-policy-check-from-create-credit-memo`
      )
      const latestPatch = res?.data?.sort(
        (a, b) => new Date(b.completedAt) - new Date(a.completedAt)
      )
      const completedTask = latestPatch?.[0] ?? null

      if (completedTask || retryCount <= 0) {
        setIsUpdatingPolicy(false)

        return completedTask?.patch?.[0]?.value?.output
      } else {
        // Wait for the specified delay before retrying
        await new Promise((resolve) => setTimeout(resolve, delay))
        return fetchTaskResult(retryCount - 1, delay) // Retry with one less retry count
      }
    }
    return await fetchTaskResult(
      NUMBER_OF_RETRIES_FETCH_POLICY_RESULT,
      RETRY_DELAY_FETCH_POLICY_RESULT
    )
  }

  const sortedSpecialConditions = sortSpecialConditions(
    caseMemoData?.caseMemo?.specialConditions?.deliveries
  )

  if (
    caseMemoData?.caseMemo?.specialConditions?.deliveries &&
    sortedSpecialConditions
  ) {
    caseMemoData.caseMemo.specialConditions.deliveries = sortedSpecialConditions
  }

  const decisionCount = flow?.data?.assessmentComments?.length ?? 0
  const options = [
    {
      id: "creditMemo",
      title: t("create-credit-memo"),
      component: (
        <InsightModule
          name={"@stacc/vue-casememo"}
          data={caseMemoData}
          onAppChange={handleAppChange}
          commonData={insightComponentData?.commonData}
          options={{
            ...caseMemoOptions,
            municipalityLookup,
            subsidyEffectCalculate,
            industryLookup: naceLookup,
            triggerPolicyCheck,
            handleEeaEvaluationChanged,
          }}
        />
      ),
    },
    {
      id: "documentations",
      title: t("requested-documentation"),
      component: (
        <AssessmentCommentsWrapper>
          <RequestedDocArchive documentations={documentation} t={t} />
        </AssessmentCommentsWrapper>
      ),
    },
  ]

  //only add tab if data
  flow?.data?.assessmentComments &&
    options.push({
      id: "assessments",
      title: t("assessment-history"),
      children: decisionCount ? <BlueDot>{decisionCount}</BlueDot> : undefined,
      component: (
        <AssessmentCommentsWrapper>
          <AssessmentComments comments={flow?.data?.assessmentComments} t={t} />
        </AssessmentCommentsWrapper>
      ),
    })

  const getFlowSchema = () => {
    const oneOf = schema?.dependencies?.decision?.oneOf
    const mainSchema = schema?.properties
    delete mainSchema?.context
    oneOf?.forEach((e) => {
      delete e?.properties?.caseOverview
      delete e?.properties?.company
      delete e?.properties?.trlAndBrl
      delete e?.properties?.conclusion
      delete e?.properties?.financePlan
      delete e?.if
      delete e?.then
      delete e?.else

      e.required = e.required.filter(
        (e) =>
          e !== "caseOverview" &&
          e !== "company" &&
          e !== "trlAndBrl" &&
          e !== "conclusion" &&
          e !== "financePlan"
      )
    })
    return schema
  }

  const overrideTranslations = (e) => {
    if (e === "allQuestionsAnswered") return t("allQuestionsAnswered")
    else return t(e)
  }

  return (
    <Layout forceHeight>
      <BMAnalyzeContent>
        <Tabs
          defaultTab="creditMemo"
          loading={isLoading || isRefetchingEngagements}
          options={options}
          onChange={(e) => handleTabChange(e)}
          showReloadButton={showReload}
        />
      </BMAnalyzeContent>

      <Context flow={flow} context={task.context?.applicationSummary}>
        <ReactForm
          schema={getFlowSchema()}
          formData={formData}
          key={rerenderId}
          disabled={isProcessing}
          onChange={(values) => {
            setFormData(values)
            setSchemaValid([])
          }}
          onSubmit={(values) => handleComplete(values)}
        >
          {schemaValid?.map((e) => {
            if (
              e === "allQuestionsAnswered" &&
              surveysNotAnswered?.length > 0
            ) {
              return (
                <SurveyErrorContainer>
                  <ErrorText>{t("surveysNotAnswered")}</ErrorText>
                  {surveysNotAnswered?.map((e) => {
                    return (
                      <ErrorText key={e}>
                        - {isAgriculture ? overrideTranslations(e) : t(e)}
                      </ErrorText>
                    )
                  })}
                  <br />
                </SurveyErrorContainer>
              )
            }
            return (
              <ErrorText key={e}>
                {isAgriculture ? overrideTranslations(e) : t(e)}
              </ErrorText>
            )
          })}

          <LoadingNotice hidden={!isRefetchingEngagements}>
            {`${t(
              "update-engagements-and-creditgroup-in-progress"
            )}  ${formatDate(time, "HH:mm:ss")}`}
          </LoadingNotice>
          <LoadingNotice hidden={!showReload}>
            {t("update-engagements-and-creditgroup-completed")}
          </LoadingNotice>
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              disabled={
                isProcessing ||
                isErrored ||
                isLoading ||
                isRefetchingEngagements ||
                showReload
              }
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButtonModified
              type="button"
              disabled={
                isProcessing ||
                isLoading ||
                isRefetchingEngagements ||
                showReload
              }
              onClick={() => handleSave()}
            >
              {t("save")}
            </SecondaryButtonModified>
          </ButtonContainer>
          {isErrored && (
            <StyledNotice backgroundColor={Colors.FerrariLighter}>
              {isErrored && <ErrorText>{t(`failed-to-load-task`)}</ErrorText>}
            </StyledNotice>
          )}
        </ReactForm>
      </Context>
    </Layout>
  )
}

const LoadingNotice = styled(Notice)`
  margin-top: 10px;
  color: ${Colors.Coal};
  background-color: ${Colors.OrangeLighter};
  border-radius: 10px;
`

const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`
const ErrorText = styled.p`
  color: ${Colors.Ferrari};
`
const StyledNotice = styled(Notice)`
  color: ${Colors.Ferrari};
  border-radius: 0;
  margin-top: 10px;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  justify-content: flex-start;
  margin-right: 1.9em;
`

const BMAnalyzeContent = styled.div`
  height: 100%;
  width: 150%;
  border-right: 1px solid #e4e2e2;
`
const AssessmentCommentsWrapper = styled.div`
  padding: 10px 40px;
  overflow-y: auto;
`
const SurveyErrorContainer = styled.div`
  margin-top: 10px;
`

export default withTranslation()(CreateCreditMemo)

/**
 * @param {Insight.SpecialConditionsDelivery[]} specialConditions - The special conditions.
 * @returns {Insight.SpecialConditionsDelivery[]} - The sorted special conditions.
 */
const sortSpecialConditions = (specialConditions) => {
  return specialConditions?.map((delivery) => {
    const userAddedPriorities = delivery.userAddedDeliveries.map(
      (term) => term.priority
    )
    // Sort the priorities
    userAddedPriorities.sort((a, b) => a - b)
    // Find the smallest missing positive integer
    let mandatoryPriority = 0
    for (let i = 0; i < userAddedPriorities.length; i++) {
      if (userAddedPriorities[i] === mandatoryPriority) {
        mandatoryPriority++
      } else if (userAddedPriorities[i] > mandatoryPriority) {
        break
      }
    }
    // Inject the mandatory
    const mandatoryTermsWithPriority =
      delivery.mandatoryDeliveries?.length > 0
        ? delivery.mandatoryDeliveries.map((term, index) => ({
            ...term,
            priority: mandatoryPriority + index,
          }))
        : []

    const allConditions = [
      ...delivery.userAddedDeliveries,
      ...mandatoryTermsWithPriority,
    ].sort((a, b) => a.priority - b.priority)

    if (allConditions && allConditions.length > 0) {
      return {
        deliveryType: delivery.deliveryType,
        userAddedDeliveries: allConditions,
        mandatoryDeliveries: [],
      }
    } else {
      return {
        deliveryType: delivery.deliveryType,
        userAddedDeliveries: delivery.userAddedDeliveries,
        mandatoryDeliveries: delivery.mandatoryDeliveries,
      }
    }
  })
}
