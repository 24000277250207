import { withTranslation } from "react-i18next"
import React, { useState } from "react"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import styled from "styled-components"
import { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import Layout from "../components/common/Layout"
import formatWithLocal from "../util/dateFormatter"
import { addThousandSeparator } from "../util/addThousandSeparator"
import { ProjectColors } from "../components/common/style/ProjectColors"

/**
 * @typedef {object} ControlAnnulmentsProps
 * @property {object} task
 * @property {any} task.data
 * @property {any} task.context
 * @property {object} flow
 * @property {Annulment.AnnulmentControlState} flow.data
 * @property {function(string): string} t
 * @property {any} schema
 * @property {function(any, function(): void, function(): void): void} save
 * @property {function(any, function(): void, function(): void): void} complete
 */

/**
 * @param {ControlAnnulmentsProps} props
 */
const ControlAnnulments = ({ task, flow, t, schema, save, complete }) => {
  const [formData, setFormdata] = useState(task.data)
  const [isProcessing, setProcessing] = useState(false)

  const handleComplete = () => {
    setProcessing(true)
    complete(
      formData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const handleSave = () => {
    setProcessing(true)
    save(
      formData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  return (
    <Layout forceHeight>
      <MainContent>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableHeader>{t("dateOfAnnulment")}</TableHeader>
              <TableHeader>{t("organizationNumber")}</TableHeader>
              <TableHeader>{t("name")}</TableHeader>
              <TableHeader>{t("accountNumber")}</TableHeader>
              <TableHeader>{t("annulledAmount")}</TableHeader>
            </TableRow>
          </TableHead>
          <tbody>
            {flow.data.annulments.map((annulment) => (
              <TableRow key={annulment.taskId}>
                <TableData>
                  {formatWithLocal(annulment.date, "dd.MM.yyyy")}
                </TableData>
                <TableData>{annulment.organizationNumber}</TableData>
                <TableData>{annulment.name}</TableData>
                <TableData>{annulment.accountNumber}</TableData>
                <TableData>
                  {annulment.amount
                    ? addThousandSeparator(annulment.amount)
                    : "-"}
                </TableData>
              </TableRow>
            ))}
          </tbody>
        </StyledTable>
      </MainContent>
      <Context flow={flow} context={task.context}>
        <ReactForm
          schema={schema}
          formData={formData}
          onChange={(values) => setFormdata(values)}
        >
          <></>
        </ReactForm>
        <ButtonContainer>
          <PrimaryButton
            type="submit"
            isLoading={isProcessing}
            disabled={isProcessing}
            onClick={() => handleComplete()}
          >
            {t("complete")}
          </PrimaryButton>
          <SecondaryButton
            type="button"
            disabled={isProcessing}
            onClick={() => handleSave()}
          >
            {t("save")}
          </SecondaryButton>
        </ButtonContainer>
      </Context>
    </Layout>
  )
}
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`
const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`

const StyledTable = styled.table`
  width: 100%;
  gap: 0px;
  border-spacing: unset;
`

const TableRow = styled.tr`
  &:nth-child(odd) {
    background-color: ${ProjectColors.Slate90};
  }
`

const TableHead = styled.thead`
  border-radius: 10px;
  background-color: ${ProjectColors.Slate90};
`

const TableHeader = styled.th`
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid ${ProjectColors.BorderColor};
`

const TableData = styled.td`
  padding: 1rem;
  text-align: left;
`

export default withTranslation()(ControlAnnulments)
